import * as React from 'react'
import { FaTimes } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import useZustand from '../hooks/useZustand'
import { getTextColor } from '../utils'
import { colorify } from '../utils/tailwind'
import { useThemeMode } from '../utils/Theme'

export default function Banners() {
  const [banners] = useZustand(state => state.banners)

  const { themeMode } = useThemeMode()

  return (
    <div className={twMerge(`relative z-0`)}>
      {banners.map((banner: any, i) => {
        const [{ lightColor, darkColor }] = colorify(banner)

        const commonProps = {
          key: banner.id,
          className: twMerge(
            `
              flex
              w-full items-center
              justify-between
              gap-2 border-b border-[rgba(0,0,0,.2)] px-1
              last:border-0
            `,
            banner.onClick && `cursor-pointer`,
            banner.className
          ),
          style: {
            background: themeMode === 'dark' ? darkColor : lightColor,
            color:
              themeMode === 'dark'
                ? getTextColor(darkColor, 'white', 'black')
                : getTextColor(lightColor, 'white', 'black'),
            ...banner.style,
          },
        }

        const content = (
          <>
            <div className="flex flex-auto items-center justify-center gap-2">
              <div className="font-medium">{banner.message}</div>
            </div>
            {banner.canClose ? (
              <button className="p-1 text-white" onClick={() => banner.close()}>
                <FaTimes className="inline-block" />
              </button>
            ) : null}
          </>
        )

        return banner.href ? (
          <a {...commonProps} href={banner.href} target={banner.target}>
            {content}
          </a>
        ) : banner.onClick ? (
          <button {...commonProps} onClick={banner.onClick}>
            {content}
          </button>
        ) : (
          <div {...commonProps}>{content}</div>
        )
      })}
    </div>
  )
}
